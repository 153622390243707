html {
   scroll-behavior: smooth;
   background-color: #cbccbf;
 }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
   0% { opacity: 0; }
   100% { opacity: 1; }
}

@keyframes shake {
   .5%, 4.5% {
      transform: translate3d(-1px, 0, 0);
   }
   
   1%, 4% {
      transform: translate3d(2px, 0, 0);
   }
 
   1.5%, 2.5%, 3.5% {
      transform: translate3d(-4px, 0, 0);
   }
 
   2%, 3% {
      transform: translate3d(4px, 0, 0);
   }
}

.tiptap {
   padding: 0 .5em;
   border: 1px solid #cccccc;
   border-radius: 7px;
}